import React from "react";
import styled from "styled-components";
import {Slide} from "react-full-page";

const DesktopWrapper = styled.div`
    
    display: flex;
    height: 100%;
    justify-content: flex-end;

    > .d1 {
        position: absolute;
        width: 100%;
        left: 0;
        padding: 32px 0;
        background: transparent linear-gradient(270deg, #935E40 0%, #4A2F2000 100%) 0% 0% no-repeat padding-box;
        
        h2 {
            font-size: 42px;
            text-align: center;
        }
       
        p {
            font-size: 18px;
            margin-top: 0px;
            text-align: center;
        }
    }
    
    > .d2 {
        align-self: flex-end;
        width: 50%;

        h3 {
            font-size: 26px;
            padding-bottom: 20px;
        }
        
        h4 {
            font-size: 18px;
            font-weight: bold;
        }
        
        p {
            margin-top: 0px !important;
        }
    }
`
const Desktop = () => {
    return (
        <DesktopWrapper>
            <div className={"d1"}>
                <h2>Tradição e bom gosto</h2>
                <p>
                    excelência em produtos e serviços
                </p>
            </div>
            <div className={"d2"}>
                <h3>O Point</h3>
                <h4>daqueles que tem bom gosto</h4>
                <p>onde se reúnem pessoas de vários<br/> segmentos da cidade.</p>
                <h4>Aqui acontecem os mais raros<br/> e diferentes "causos" da cidade.</h4>
                <br />
                <p>
                    Ambiente alegre e descontraído, onde os clientes<br/>
                    mais assíduos "batem ponto" para saborear os<br/>
                    deliciosos cafés com biscoitos, acompanhados<br/>
                    de um bom e agradável papo.
                </p>
            </div>
        </DesktopWrapper>
    );
}

const MobileWrapper = styled.div`
    text-align: center;
    padding-top: 100px;
    
    p {
        margin-top: 0;
    }
`
const Mobile = () => {
    return (
        <MobileWrapper>
            <h2>Tradição e bom gosto</h2>
            <p>
                excelência em produtos e serviços
            </p>
        </MobileWrapper>
    );
}

export const Slide1 = ({dispositive}) => {
    return (
        <Slide className={dispositive}>
            {dispositive === 'mobile' ? <Mobile dispositive={dispositive}/> : <Desktop dispositive={dispositive}/>}
        </Slide>
    );
}