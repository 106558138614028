import React, {useEffect, useState} from "react";
import styled from "styled-components";

const VideoWrapper = styled.div`
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
`
const Video = ({src}) => {
    return (
        <VideoWrapper>
            <iframe
                width="853px"
                height="480px"
                src={src}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </VideoWrapper>
    );
}

export const SlideVideo = ({dispositive}) => {
    const [src, setSrc] = useState('');
    //https://www.youtube.com/embed/aJR7f45dBNs?controls=0

    useEffect(() => {

        async function getData() {
            try {
                const res = await fetch('https://api.bocadepito.com.br/videos/5.json');
                const json = await res.json();

                if (json.success) {
                    const data = json.data;
                    if(data['active']){
                        setSrc(data['src']);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }

        // noinspection JSIgnoredPromiseFromCall
        getData();

    }, []);

    if(!src){
        return <React.Fragment/>
    }

    return (
        <div className={dispositive === "mobile" ? "mobile full" : "desktop"}>
            <Video dispositive={dispositive} src={src}/>
        </div>
    );
}