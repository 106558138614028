import React from "react";
import {FullPage} from 'react-full-page';

import {Global, HomeWrapper} from "./style"

import {Slide1} from "./components/Slide1";
import {Slide1M} from "./components/Slide1M";
import {SlideVideo} from "./components/SlideVideo";
import {Slide2} from "./components/Slide2";
import {Slide3} from "./components/Slide3";
import {Slide4} from "./components/Slide4";
import {Slide5} from "./components/Slide5";

export const Home = () => {

    return (
        <>
            <Global dispositive={window["dispositive"]}/>
            <HomeWrapper isMobile={window["isMobile"]}>
                <FullPage
                    // beforeChange={handleChange}
                    scrollMode={"normal"}
                >
                    <Slide1 dispositive={window["dispositive"]}/>
                    {
                        window['isMobile'] && <Slide1M/>
                    }
                    <SlideVideo dispositive={window["dispositive"]}/>
                    <Slide2 dispositive={window["dispositive"]}/>
                    <Slide3 dispositive={window["dispositive"]}/>
                    <Slide4 dispositive={window["dispositive"]}/>
                    <Slide5 dispositive={window["dispositive"]}/>
                </FullPage>
            </HomeWrapper>
        </>
    );
}