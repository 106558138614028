import styled from "styled-components";

export const AppWrapper = styled.div`

    color: ${(props) => props.theme === "dark" ? "black" : "white"};
    height: 100%;
    background-color: ${(props) => props.menu==="show" ? "black" : "transparent" };
    
    > div {
        &.header,
        &.footer{
            position: fixed;
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 24px;
            flex-direction: column;
            
            .logo {
                text-align: center;
                min-width: 280px;
            }
        }
        
        &.header {
            padding-bottom: 0;
        }
        
        &.footer {
            bottom: 0;
            z-index: 1;
                       
            .bar {
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 1366px;
                width: 1140px;
                margin: 0 auto;
                
                .logo {
                    i {
                        font-size: 20px;
                    }
                    line-height: 8px;
                    
                    a {
                        font-size: 15px;
                        color: #FFF;
                        text-decoration: none;
                        width: 50%;
                    }
                    
                    a:active {
                        color: #FFF;
                    }
                    
                    a:hover {
                        padding-bottom: .5rem;
                    }
                }
                
                .left, .right {
                    border-bottom: 1px solid #FFF;
                    flex-grow: 1;
                }
            }
            
            .main-menu {
                position: absolute;
                border-top: 2px solid #505050;
                top: 100px;
                right: 0;
                width: 100%;
                background-color: black;
                height: 1000px;
                  
                a {
                    display: block;
                    width: 100%;
                    color: white;
                    text-decoration: none;
                    font-weight: bold;
                    font-size: 18px;
                    text-align: center;
                    padding: 1rem;
                    border-bottom: 2px solid #505050;
                }
            }
        }
        
        &.footer.desktop{
            background-color: ${props => props.internal ? "#000" : "inherit"};
            a {
                font-weight: bold;
            }
        }

        &.footer.mobile:not(.slide-top){
            .logo.lhide {
                opacity: 0;
            }
        }

        &.footer.mobile{
            
            .bar{
                width: 100% !important;
                min-width: 100% !important;
            }
            
            .left, .right {
                display: none;
            }
        }
        
        &.header {
            transition: background-color 0.5s ease;
            z-index: 2;
           
            span {
                position: absolute;
                font-size: 25px;
                right: 24px;
                top: 24px;
            }
        }
        
        &.header.mobile:not(.internal) {
        
            display: ${(props) => props.dispositive === "mobile" && props.internal && props.menu !== "show" ? "none" : "block"};
        
            ${(props) => props.menu === "show" ? "background: inherit;" : ""};
        
            &.moved{
                background-color: black !important;
            }
        
            .left, .right {
                display: none;
            }
        }
        
        &.header.mobile.internal {
            display: ${(props) => props.dispositive !== "mobile" || !props.internal || props.menu === "show" ? "none": "block"};
            padding: 8px;
            
            > div {
                display:  flex;
                align-items: center;
                border-radius: 12px;
                background-color: rgba(75, 75, 77, .7);
                padding: 16px;
                
                > div:first-child {
                    flex-grow: 1;
                    font-weight: bold;
                    font-size: 18px;
                    text-transform: uppercase;
                    letter-spacing: 1.8px;
                }
            }
        
            span {
                position: relative;
                top: inherit;
                right: inherit;
            }
        }
        
        &.header.desktop {
            background-color: ${props => props.internal ? "#000" : "inherit"};
            
            > div {
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 1366px;
                width: 1140px;
                margin: 0 auto;
               
                .left, .right {
                    border-bottom: 1px solid #FFF;
                    flex-grow: 1;
                    display: flex;
                    align-items: flex-end;
                    height: 50px;
                    // display: ${props => props.internal ? "block" : "none"};
                                       
                    a {
                        font-size: 16px;
                        color: #FFF;
                        text-decoration: none;
                        width: 50%;
                        line-height: 40px;
                        font-weight: bold;
                    }
                    
                    a:active {
                        color: #FFF;
                    }
                    
                    a:hover {
                        padding-bottom: .5rem;
                    }
                }
                
                .left {
                    text-align: left;
                }
                
                .right {
                    text-align: right;
                }
            }
        }
    }
    
    
`