import React from "react";
import TinySlider from "tiny-slider-react";
import styled from "styled-components";

const imgStyles2 = {
    width: "100%",
    height: "auto",
};

const settings2 = {
    nav: false,
    mouseDrag: false,
    controls: false,
    items: 1,
    gutter: 5,
    responsive: {
        420: {
            items: 3
        }
    },
    loop: true,
    autoplay: true,
};

const DesktopWrapper = styled.div`
    background-color: #FFF;
    padding: 26px 0;
    
    > div:not(.full) {
        max-width: 1366px;
        width: 1140px;
        margin: 0 auto;
        text-align: center;
        color: #000;
                
        h3 {
            font-size: 32px;
            padding-bottom: 20px;
        }
    }
    
    div:nth-child(2) {
        display: flex;
        img {
            width: 33.33%;
            padding: 24px 0;
        }
    }
`

const Desktop = () => {
    return (
        <DesktopWrapper>
            <div>
                <h3>Tabacaria</h3>
                Produtos de alta qualidade, incluindo:
                <h4>cachimbos, charutos, piteiras, narguilés e acessórios.</h4>
            </div>
            <div className={"full"}>
                <img
                    src={"/images/home/mobile/tabacaria-1.jpg"}
                    data-src={"/images/home/mobile/tabacaria-1.jpg"}
                />
                <img
                    src={"/images/home/mobile/tabacaria-2.jpg"}
                    data-src={"/images/home/mobile/tabacaria-2.jpg"}
                />
                <img
                    src={"/images/home/mobile/tabacaria-3.jpg"}
                    data-src={"/images/home/mobile/tabacaria-3.jpg"}
                />
            </div>
            <div>
                E também as mais variadas marcas de fumo:
                <h4>Half, Prince Albert, Amphora, Trosst, etc.</h4>
            </div>
        </DesktopWrapper>
    );
}

const MobileWrapper = styled.div`
    background: #FFF;
    color: #000;
    padding: 24px 0;
    text-align:center;
    
    h3 {
        font-size: 24px;
        padding-bottom: 8px;
    }
    
    h5 {
        font-size: 18px;
        font-weight: bold;
    }
    
    img {
        width: 100%;
        height: auto;
        padding: 24px 0;
    }
`
const Mobile = () => {
    return (
        <MobileWrapper>
            <h3>Tabacaria</h3>
            Produtos de alta qualidade, incluindo:
            <h5>cachimbos, charutos, piteiras, narguilés e acessórios.</h5>
            <TinySlider settings={settings2}>
                <div style={{position: "relative"}}>
                    <img
                        src={"/images/home/mobile/tabacaria-1.jpg"}
                        data-src={"/images/home/mobile/tabacaria-1.jpg"}
                        alt=""
                        style={imgStyles2}
                    />
                </div>
                <div style={{position: "relative"}}>
                    <img
                        src={"/images/home/mobile/tabacaria-2.jpg"}
                        data-src={"/images/home/mobile/tabacaria-2.jpg"}
                        alt=""
                        style={imgStyles2}
                    />
                </div>
                <div style={{position: "relative"}}>
                    <img
                        src={"/images/home/mobile/tabacaria-3.jpg"}
                        data-src={"/images/home/mobile/tabacaria-3.jpg"}
                        alt=""
                        style={imgStyles2}
                    />
                </div>
            </TinySlider>
            E também as mais variadas marcas de fumo:
            <h5>Half, Prince Albert, Amphora, Trosst, etc.</h5>
        </MobileWrapper>
    );
}

export const Slide3 = ({dispositive}) => {
    return (
        <div className={dispositive + " full"}>
            {dispositive === 'mobile' ? <Mobile dispositive={dispositive}/> : <Desktop dispositive={dispositive}/>}
        </div>
    );
}