import React from "react";
import styled from "styled-components";
import {Slide} from "react-full-page";

const DesktopWrapper = styled.div`
    display: flex;
    height: 100%;
    > div {
        width: 50%;
        
        &:nth-child(1){
            background: transparent linear-gradient(90deg, #000000 0%, #935E40 100%) 0% 0% no-repeat padding-box;
            
            padding-top: 120px;
            
            > div {
                position: absolute;
                width: 1140px;
                max-width: 1366px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
            }
            
            h3 {
                font-size: 32px;
                padding-bottom: 32px;
            }
            
            h4 {
                font-size: 20px;
                font-weight: bold;
                position: initial;
            }
        }
        
        &:nth-child(2){
            background-image: url("/images/home/mobile/details-2.png");
            background-position: top;
            background-size: cover;
        }
    }
`
const Desktop = () => {
    return (
        <DesktopWrapper>
            <div>
                <div>
                    <h3>Cafeteria</h3>
                    <h4>Inaugurada em 1977,</h4>
                    <p>
                        foi a Boca de Pito que implementou,<br />
                        em Belo Horizonte, o café servido em xícaras<br />
                        de porcelana esterilizadas a vapor.
                    </p>
                    <p>
                        Foi também a 1ª a trazer o café expresso e <br />
                        o café com creme para a capital.
                    </p>
                </div>
            </div>
            <div/>
        </DesktopWrapper>
    );
}

const MobileWrapper = styled.div`
    background: transparent linear-gradient(90deg, #000000 0%, #935E40 100%) 0% 0% no-repeat padding-box;
    padding: 24px 0;
    text-align:center;
    
    h3 {
        font-size: 24px;
        padding-bottom: 8px;
    }
    
    h5 {
        font-size: 18px;
        font-weight: bold;
    }
    
    img {
        width: 100%;
        height: auto;
        padding: 24px 0;
    }
`
const Mobile = () => {
    return (
        <MobileWrapper>
            <h3>Cafeteria</h3>
            <img src={"/images/home/mobile/details-2.png"} />
            <h5>Inaugurada em 1977,</h5>
            <p>
                foi a Boca de Pito que implementou,<br />
                em Belo Horizonte, o café servido em xícaras<br />
                de porcelana esterilizadas a vapor.
            </p>
            <p>
                Foi também a 1ª a trazer o café expresso e <br />
                o café com creme para a capital.
            </p>
        </MobileWrapper>
    );
}

export const Slide2 = ({dispositive}) => {
    return (
        <div className={dispositive+" full"} style={dispositive === 'desktop' ? {height: 480} : {}}>
            {dispositive === 'mobile' ? <Mobile dispositive={dispositive}/> : <Desktop dispositive={dispositive}/>}
        </div>
    );
}