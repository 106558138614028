import React from "react";
import styled from "styled-components";

const DesktopWrapper = styled.div`
    display: flex;
    height: 100%;
    > div {
        width: 50%;
        
        &:nth-child(1){
            background-image: url("/images/home/mobile/presentes-2.png");
            background-position: top;
            background-size: cover;
        }
        
        &:nth-child(2){
            background: transparent linear-gradient(270deg, #000000 0%, #935E40 100%) 0% 0% no-repeat padding-box;
            
            padding-top: 120px;
            
            > div {
                position: absolute;
                width: 1140px;
                max-width: 1366px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;

                > div {
                    position: absolute;
                    padding-left: 80px;
                    width: 50%;
                    right: 0;
                }
            }
            
            h3 {
                font-size: 32px;
                padding-bottom: 32px;
            }
            
            h4 {
                font-size: 20px;
                font-weight: bold;
                position: initial;
            }
        }
    }
`
const Desktop = () => {
    return (
        <DesktopWrapper>
            <div/>
            <div>
                <div>
                    <div>
                        <h3>Presentes</h3>
                        <p>Uma seleção especial e variada:</p>
                        <h4>
                            Produtos originais<br/>
                            Marcas renomadas<br/>
                            Presentes requintados
                        </h4>
                        <p>
                            E você ainda conta com um atendimento exclusivo,<br/>
                            com hora marcada.
                        </p>
                    </div>
                </div>
            </div>
        </DesktopWrapper>
    );
}

const MobileWrapper = styled.div`
    background: transparent linear-gradient(270deg, #000000 0%, #935E40 100%) 0% 0% no-repeat padding-box;
    color: #FFF;
    padding: 24px 0;
    text-align:center;
    
    h3 {
        font-size: 24px;
        padding-bottom: 8px;
    }
    
    h5 {
        font-size: 18px;
        font-weight: bold;
    }
    
    img {
        width: 100%;
        height: auto;
        padding: 24px 0;
    }
`
const Mobile = () => {
    return (
        <MobileWrapper>
            <h3>Presentes</h3>
            <img src={"/images/home/mobile/presentes-2.png"} />
            <p>Uma seleção especial e variada:</p>
            <h5>
                Produtos originais<br/>
                Marcas renomadas<br/>
                Presentes requintados
            </h5>
            <p>
                E você ainda conta com um atendimento<br/>
                exclusivo, com hora marcada.
            </p>
        </MobileWrapper>
    );
}

export const Slide4 = ({dispositive}) => {
    return (
        <div className={dispositive + " full"} style={dispositive === 'desktop' ? {height: 480} : {}}>
            {dispositive === 'mobile' ? <Mobile dispositive={dispositive}/> : <Desktop dispositive={dispositive}/>}
        </div>
    );
}