import styled from "styled-components";

export const Wrapper = styled.div`  
    
    height: 100%;
    
    h3 {
        font-size: 38px;
    }
    
    p.strong {
        font-weight: bold;
        font-size: 18px;
    }
    
    label {
        font-size: 12px;
        width: 100%;
        padding-bottom: .5rem;
    }
         
    div.welcome {
        text-align: center;
        margin: 2.5rem 0;
        font-size: 20px;
        font-weight: bold;
        width: 100%;
        flex-grow: 1;
    }
         
    form {
        
        margin-top: 50px;
    
        > div {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
        }
        
        input {
            width: 100%;
            padding: .5rem;
            border-radius: 6px;
            border: 1px solid #FFF;
            background-color: black;
            color: #FFF;
            font-size: 20px;
        }
        
        button {
            background-color: #FFF;
            text-align: center;
            line-height: 48px;
            width: 100%;
            border-radius: 6px;
            font-size: 18px;
            border: 0;
            min-width: 180px;
        }
    }
    
     div.inline {
        display: flex;
        
        input:not(:last-child){
            text-align: center;
        }
        
        > div:not(:last-child) {
            margin-right: 1rem;
            text-align: center;
        }
    }
    
    > div.mobile {
        padding-top: 20px;

        p.strong {
            text-align: center;
        }

        div.banner {
            background-image: url("/images/contato/mobile/banner.png");
            background-size: cover;
            background-position: center top;
            height: 260px;
        }
        
        div.content {
            padding: 1.5rem;
        }
    }
    
    div.desktop {
        color: #FFF;
        padding-top: 128px;
        max-width: 1366px;
        width: 1140px;
        margin: 0 auto;
        
        div.welcome {
            text-align: left;
        }
        
        > div.w50 {
            display: flex;
                    
            > div{
                width: 50%;
                
                :nth-child(2){
                    background-image: url("/images/contato/mobile/banner.png");
                    background-size: cover;
                    background-position: center -80px;
                    /*min-height: 440px;*/
                }
                
            }
             
        }
        
        > div.w100 {
            border-top: 2px solid #FFF;
            padding: 1rem 0;
            display: flex;
            > div {
                :nth-child(1){
                    flex-grow: 1;
                    line-height: 20px;
                }
                
                :nth-child(2){
                    text-align: right;
                    line-height: 20px;
                    > div {
                        display: flex;
                        padding-top: .5rem;
                        
                        > div {
                            text-align: left;
                            align-items: center;
                            display: flex;
                            
                            :nth-child(1){
                                flex-grow: 1;
                            }
                            
                            :nth-child(2){
                                i {
                                    font-size: 24px;
                                    margin-right: 4px;
                                }
                                
                                a {
                                    align-items: center;
                                    display: flex;
                                }
                            }
                        }
                    }
                }
            }
        }
        
        form {
            > div {
                flex-direction: row;
                align-items: center;
            }
            
            button {
                width: auto;
            }
        }
        
        label {
            width: inherit;
            margin-right: 1rem;
        }
    }
`