import React, {createRef} from "react";
import {FullPage, Slide} from 'react-full-page';
import TinySlider from "tiny-slider-react";
import {Global, Wrapper} from "./style";

const sliderRef = createRef();

const imgStyles = {
    width: "100%",
    height: window['isMobile'] ? "auto" : "auto",
    objectFit: "cover",
    objectPosition: "top",
};

const settings = {
    nav: false,
    mouseDrag: true,
    controls: false,
    items: 1,
    loop: true,
    responsive: {
        420: {
            items: 5
        }
    }
    // autoplay: true,
};

const images = [
    {
        label: "Sempre pronto para atender.",
    },
    {
        label: "Localizada no shopping 5ª Avenida, no coração da Savassi.",
    },
    {
        label: "Tradição e excelência em cafeteria.",
    },
    {
        label: <>Experimente e leve:<br />Café especial moido na hora.</>,
    },
    {
        label: "Cachaça Premium Boca de Pito.",
    },
    {
        label: "Uma parada para um café, pito e boa prosa.",
    }
];

const Slider1Text = () => (
    <>
        <div>
            <h2>O Point</h2>
            <p>do tradicional café expresso,<br/>no coração da Savassi.</p>
        </div>
        <br />
        <div>
            <h4>Já tomou seu café hoje?</h4>
        </div>
    </>
);

const Slider1Dektop = () => (
    <div className={"desktop"}>
        <div>
            <Slider1Text/>
        </div>
        <div className={"banner"}/>
    </div>
);

const Slider1Mobile = () => (
    <div className={"mobile"}>
        <div className={"banner"}/>
        <Slider1Text/>
    </div>
);

export const Cafeteria = () => {

    const goTo = (dir) => {
        sliderRef.current.slider.goTo(dir);
    }

    return (
        <Wrapper>
            <Global dispositive={window["dispositive"]}/>
            <FullPage scrollMode={"normal"}>
                <Slide>
                    {/*<div className={"banner"}/>*/}
                    {/*<Slider1Text/>*/}
                    {
                        window["isMobile"] ? <Slider1Mobile/> : <Slider1Dektop/>
                    }
                </Slide>
                <Slide className={window["dispositive"]}>
                    <div className={window["dispositive"]}>
                        <h2>Qualquer motivo</h2>
                        <p>é sempre um bom motivo<br/> para um café</p>
                    </div>
                </Slide>
                <div>
                    <div className={window["dispositive"]}>
                            {
                                images.map((image, index) => {
                                        const src = `/images/cafeteria/${index}.png`;
                                        return (
                                            <div style={{position: "relative"}}>
                                                <img
                                                    src={src}
                                                    data-src={src}
                                                    alt=""
                                                    style={imgStyles}
                                                />
                                                <div>{image["label"]}</div>
                                            </div>
                                        )
                                    }
                                )
                            }
                    </div>
                </div>
            </FullPage>
        </Wrapper>
    );
}