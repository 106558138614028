import styled, {createGlobalStyle} from "styled-components";

export const Global = createGlobalStyle`
    body {
        background-image: url("/images/cafeteria/${props => props.dispositive}/bg.jpg");
    }
`

export const Wrapper = styled.div`
    text-align: center;
    
    > div {
         > div:nth-child(1){
            background-color: black;
            
            h2 {
                font-size: 64px;
            }
            
            p {
                margin-top: 0px;
                font-size: 22px;
            }
            
            h4 {
                font-size: 32px;
                font-weight: normal;
                padding-top: 24px;
            }

            div.banner {
                background-image: url("/images/cafeteria/mobile/banner.jpg");
                background-size: cover;
                background-position: center bottom;
            }
            
            > div.desktop {
                padding-top: 128px;
                padding-bottom: 76px;
                display: flex;
                max-width: 1140px;
                margin: 0 auto;
                height: 100%;
                
                > div {
                    width: 50%;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                }
            }
            
            > div.mobile {
                display: flex;
                flex-direction: column;

                h2 {
                    margin-top: 70px;
                    font-size: 38px;
                }
                
                p {
                    font-size: 15px;
                }

                h4 {
                    font-size: 18px; 
                }

                div.banner {
                    height: 260px;
                }

                div:last-child {
                    position: absolute;
                    bottom: 110px;
                    width: 100%;
                    font-size: 20px;
                }            
            }
        }
        
        > div:nth-child(3){
            background: transparent linear-gradient(90deg, #935E40 0%, #000000 100%) 0% 0% no-repeat padding-box;
            
            > div.desktop {
                padding: 110px 1.5rem 110px;

                display: flex;
                max-width: 1140px;
                margin: 0 auto;
                height: 100%;
                flex-wrap: wrap;
                 > div {
                    width: 33.33%;
                    padding: 4px;
                    > div {
                        font-size: 16px;
                        text-align: center;
                        padding: 8px;
                    }
                 }
            }
            
            > div.mobile {
                padding: 32px 16px;
                
                img {
                    padding-bottom: 8px;
                }
                
                > div {
                    padding-bottom: 24px;
                }
            }
                        
        }
        
        > div:nth-child(2){
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
                 
            h2{
                text-shadow: 2px 2px 1px rgba(0, 0, 0, .7);
            }

            p {
                text-shadow: 2px 2px 1px rgba(0, 0, 0, .7);
            }

            &.mobile {
                padding-top: 74px;
                
                h2 {
                    font-size: 32px;
                }              
                p {
                    font-size: 18px;
                }
            }

            &.desktop {
                padding-top: 130px;
                padding-bottom: 74px;
                h2 {
                    font-size: 48px;
                }              
                p {
                    font-size: 22px;
                }
            }
            
            
            > div {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                background-repeat: no-repeat;
                background-attachment: fixed;
                background-size: cover;
            }
        }
    }
`