import React, {useEffect, useState} from "react";
import TinySlider from "tiny-slider-react";

import {Wrapper} from "./style";

const imgStyles = {
    width: "100%",
    height: "160px",
    objectFit: "cover",
    objectPosition: "top",
};

const settings = {
    // lazyload: true,
    nav: false,
    mouseDrag: true,
    controls: false,
    items: 1,
    gutter: 5,
    loop: false
};

const categories = [
    {
        image: 'charutos',
        label: 'Charutos',
        products : [
            {
                image: "1.png",
                label: "Dona Flor Gran Corona Graduado - 10 Unid",
            },{
                image: "2.png",
                label: "Dona Erô caixa - 25 Unid",
            },{
                image: "3.png",
                label: "Dannemann Santo Antônio robusto - 10 Unid",
            },{
                image: "4.png",
                label: "Cohiba Robusto - 25 Unid",
            },{
                image: "5.png",
                label: "Alonso Menendez Dele Patron - 10 Unid",
            },{
                image: "6.png",
                label: "Monte Pascoal Divinos - 19 Unid",
            },{
                image: "7.png",
                label: "Cohiba Selection - 30 Unid",
            },
        ]
    }, {
        image: 'cachimbos',
        label: 'Cachimbos',
        products: [
            {
                image: "1.png",
                label: "Pipe - Bali-Hài",
            },{
                image: "2.png",
                label: "Raiz de Roseira Toscania",
            },{
                image: "3.png",
                label: "Raiz de Roseira Liburbia",
            },{
                image: "4.png",
                label: "Raiz de Roseira Paládio",
            },{
                image: "5.png",
                label: "Pipe - Mahalo (Cartão de Aço)",
            }
        ]
    }, {
        image: 'narguiles',
        label: 'Narguilés',
        products: [
            {
                image: "1.png",
                label: "Fumo Al Fakher Strawberry"
            },{
                image: "2.png",
                label: "Fumo Al Fakher Capuccino"
            },{
                image: "3.png",
                label: "Carvão Bàli-hài (10Uni.)"
            },{
                image: "4.png",
                label: "Fumo Al Fakher Blueberry"
            },{
                image: "5.png",
                label: "Fumo Al Fakher Kiwi"
            }
        ]
    }, {
        image: 'piteiras',
        label: 'Piteiras',
        products: [
            {
                image: "1.png",
                label: "Piteira Friend Holder Dandy",
            },{
                image: "2.png",
                label: "Piteira Friend Holder Max",
            },{
                image: "3.png",
                label: "Piteira Italiana Para Cigarro (Madeira Raiz de Roseira)",
            },{
                image: "4.png",
                label: "Piteira Sandra Holder (7 uni.)",
            },{
                image: "5.png",
                label: "Piteira Targard Permanente",
            }
        ]
    }, {
        image: 'cigarros',
        label: 'Cigarros',
        products: [
            {
                image: "1.png",
                label: "Marlboro Filter Plus Box",
            },{
                image: "2.png",
                label: "Pradense Orgânico",
            },{
                image: "3.png",
                label: "Rolo Tabak Lata",
            },{
                image: "4.png",
                label: "Souza Paiol Série Ouro",
            },{
                image: "5.png",
                label: "Tabaco Manitou Azul Virginia",
            }
        ]
    }, {
        image: 'cinzeiros',
        label: 'Cinzeiros',
        products: [
            {
                image: "1.png",
                label: "Cinzeiro Metal Redondo - Grande",
            },{
                image: "2.png",
                label: "Cinzeiro Habana Cuba",
            },{
                image: "3.png",
                label: "Cinzeiro Dinghad Dourado e Marrom",
            },{
                image: "4.png",
                label: "Cinzeiro Monte Cristo 2 Pontas",
            },{
                image: "5.png",
                label: "Cinzeiro Redondo",
            }
        ]
    }, {
        image: 'isqueiros',
        label: 'Isqueiros',
        products: [
            {
                image: "1.png",
                label: "Isqueiro de Fluído Parker Flaminaire",
            },{
                image: "2.png",
                label: "Isqueiro Imco Streamline",
            },{
                image: "3.png",
                label: "Isqueiro-Cigarreira Honson",
            },{
                image: "4.png",
                label: "Zippo Borboleta",
            },{
                image: "5.png",
                label: "Zippo Pipe Lighter Escovado",
            }
        ]
    }, {
        image: 'outros',
        label: 'Outros',
        products: [
            {
                image: "1.png",
                label: "Fluído Volcano",
            },{
                image: "2.png",
                label: "Cigarreira de Metal Trabalhada",
            },{
                image: "3.png",
                label: "Furador e Socador Madeira",
            },{
                image: "4.png",
                label: "Porta Cigarro Couro de Cabra",
            },{
                image: "5.png",
                label: "Tabaco Captain Black Royal",
            }
        ]
    }
];

const imagePath = "/images/tabacaria";

export const Tabacaria = () => {
    const [active, setActive] = useState(categories[0]);

    useEffect(() => {
        // document.getElementById("images").scrollTo(0, 0);
        window.scrollTo(0, 0)
    }, [active]);

    return (
        <Wrapper isMobile={window['isMobile']}>
            <div className={`slider ${window["dispositive"]}`}>
                <div>
                {
                    categories.map((category, index) => {

                        const status = active.image === category.image ? "on" : "off";

                        return (
                            <div key={`category-${index}`} onClick={() => setActive(category)} className={status}>
                                <div>
                                    <img src={`${imagePath}/icons/${window["dispositive"]}/${category.image}-${status}.svg`}/>
                                </div>
                                {!window['isMobile'] && category.label}
                            </div>
                        )
                    })
                }
                </div>
            </div>
            {
                window['isMobile'] && <div className={"label"}>{active.label}</div>
            }
            <div id={"images"} className={`images ${window["dispositive"]}`}>
                {
                    active.products.map((product) => (
                        <div>
                            <img
                                src={`${imagePath}/products/${active.image}/${product.image}`}
                                alt=""
                            />
                            <div>{product.label}</div>
                        </div>
                    ))
                }
            </div>

            {/*<TinySlider settings={settings}>*/}
            {/*    {*/}
            {/*        active.products.map((product) => (*/}
            {/*            <div style={{position: "relative"}}>*/}
            {/*                <img*/}
            {/*                    // src={"/images/home/mobile/contact-1.png"}*/}
            {/*                    src={`${imagePath}/products/${active.image}/${product.image}`}*/}
            {/*                    alt=""*/}
            {/*                    width={"90%"}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        ))*/}
            {/*    }*/}
            {/*</TinySlider>*/}
        </Wrapper>
    );
}