import styled from "styled-components";

export const Wrapper = styled.div`
    /*background-color: black;*/
    padding: ${(props) => props.isMobile ? "84px 1.5rem 0" : "110px 1.5rem 76px"};
    height: ${(props) => props.isMobile ? "100%" : "inherit"};
    display: flex;
    flex-direction: column;
    
    .slider {
        > div {
            margin: 0 -1.5rem;
            display: flex;
            overflow-x: auto;
            padding: 0 .5rem;
    
            > div {
                margin: 0 .5rem;
                cursor: pointer;
            }
        }
    }
    
    .slider.desktop{
        /*margin: 1.5rem auto;*/
        /*width: 1140px;*/
        margin: 0 -1.5rem;
        position: fixed;
        background-color: black;
        width: 100%;
        /*
        left: 50%;
        transform: translateX(-50%);
        */
        /*justify-content: center;*/
        padding: 1.5rem;
        z-index: 1;
        
        > div {
            width: 1140px;
            margin: 0 auto;
            > div {
                text-transform: uppercase;
                text-align: center;
                font-size: 16px;
                line-height: 24px;
                
                &.on {
                    border-bottom: 1px solid #FFF;
                }
                
                &.off {
                    opacity: .7;
                }
                
                > div {
                    display: flex;
                    width: 125px;
                    height: 80px;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    
    .label {
        font-size: 15px;
        margin: 1.5rem 0;
        justify-content: center;
        text-transform: uppercase;
        display: flex;
    }
    
    .images {
        display: flex;
        text-align: center;
        overflow-x: auto;
        height: 0;
        flex-grow: 1;
        
        > div {
            margin-right: .5rem;
            display: flex;
            flex-direction: column;
            position: relative;

            img {
                width: auto;
                height: calc(100vh - 360px);
                object-fit: contain;
            }

            > div {
                padding: .5rem 1rem;
                text-transform: capitalize;
            }
        }
    }
    
    .images.desktop {
        background-color: #FFF;
        color: #000;
        flex-wrap: wrap;
        justify-content: center;
        /*
        padding: 1.5rem;
        margin: 0 -1.5rem;
        */
        height: inherit !important;
        overflow-x: inherit !important;
        padding-top: 168px;
        
        img {
            width: auto;
            height: calc(100vh - 420px);
        }
        
        > div {
            width: 40%;
            margin-bottom: 2rem;
        }
    }
    
`