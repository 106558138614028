import React, {useEffect, useState} from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import {Wrapper} from "./style";

const Item = ({item}) => (
    <div className={"content"}>
        <div>
            <div className={"title"}>
                <img src={"/images/curiosidades/icons/xicara-50.svg"} width={50} height={50}/>
                <h2>{item.type_text}:</h2>
            </div>
            <div>{item.title}</div>
            <div dangerouslySetInnerHTML={{__html: item.content}}/>
        </div>
    </div>
)

const Pagination = (pagination) => {
    return (
        <div className={"content old"}>
            <div style={{textAlign: "center"}}>
                <span>Voltar</span>
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span>Avançar</span>
            </div>
        </div>
    );
}

export const Curiosidades = () => {
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [items, setItems] = useState([]);

    const limit = 2;
    const fetchPosts = async () => {
        try {
            const res = await fetch(`https://api.bocadepito.com.br/posts.json?page=${page}&limit=${limit}`);
            const json = await res.json();

            if (json.success) {
                setItems([...items, ...json.data]);
                setPage(page+1);
                setHasMore(json.pagination.has_next_page || false);
            }
        }catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        // async function getData() {
        //
        // }

        // noinspection JSIgnoredPromiseFromCall
        fetchPosts();
    }, []);

    return (
        <Wrapper isMobile={window["isMobile"]}>
            <div className={"banner"}/>

            <InfiniteScroll
                dataLength={items.length}
                next={fetchPosts}
                hasMore={hasMore}
                loader={<div style={{padding: '1rem', textAlign: 'center'}}>carregando...</div>}
                className={"items"}
            >
            {
                items.map((item) => <Item key={`item-${item.id}`} item={item}/>)
            }
            </InfiniteScroll>
        </Wrapper>
    );
}