import React, {createRef, useCallback, useEffect, useState} from "react";
import {Route, Switch, useLocation} from "react-router-dom";

import {Cafeteria, Contato, Curiosidades, Home, Presentes, Tabacaria} from "./pages";

import {AppWrapper} from "./style";

const refFooter = createRef();

window['isMobile'] = window.screen.width <= 1024;
window['dispositive'] = window['isMobile'] ? 'mobile' : 'desktop';
const dispositive = window['isMobile'] ? 'mobile' : 'desktop';
const labels = {
    "/cafeteria": "Cafeteria",
    "/tabacaria": "Tabacaria",
    "/presentes": "Presentes",
    "/curiosidades": "Curiosidades",
    "/contato": "Contato",
}

const App = () => {
    const location = useLocation();
    const [menu, setMenu] = useState("");
    const [theme, setTheme] = useState("light");
    const [pos, setPos] = useState("top");

    const toogleMenu = useCallback(() => {
        if (menu === "") {
            setPos("moved");
            setMenu("show");
            refFooter.current.classList.remove("slide-bottom");
            refFooter.current.classList.add("slide-top");
        } else {
            setPos("top");
            setMenu("");
            refFooter.current.classList.remove("slide-top");
            refFooter.current.classList.add("slide-bottom");
        }
    }, [menu]);

    useEffect(() => {

        if (location.pathname === "/contato") {
            if (dispositive === "desktop") {
                refFooter.current.style.display = "none";
            }
        }

        document.addEventListener("scroll", () => {
            if (!["/curiosidades", "/contato"].includes(location.pathname)) {
                return;
            }
            let scrolled = document.scrollingElement.scrollTop;
            if (scrolled >= 150) {
                setPos("moved");
            } else {
                setPos("top");
            }
        })
    }, []);

    return (
        // <Router>
        <AppWrapper theme={theme} menu={menu} dispositive={dispositive} internal={location.pathname !== "/"}>
            <div className={`header ${dispositive}`}>
                <div>
                    <div className={"left"}>
                        <a href="/cafeteria">CAFETERIA</a>
                        <a href="/tabacaria">TABACARIA</a>
                    </div>
                    <div className={"logo"}>
                        <a href={"/"}>
                            <img
                                width={200}
                                src={`/images/logo-${dispositive}-${theme}.svg`}
                            />
                        </a>
                    </div>
                    <div className={"right"}>
                        <a href="/presentes">PRESENTES</a>
                        <a href="/curiosidades">CURIOSIDADES</a>
                    </div>
                </div>
                {
                    window["isMobile"] && <span
                        className={"icon-menu"}
                        onClick={toogleMenu}
                    />
                }
            </div>
            <div className={"header mobile internal"}>
                <div>
                    <div>{labels[location.pathname]}</div>
                    <div>
                        <span
                            className={"icon-menu"}
                            onClick={toogleMenu}
                        />
                    </div>
                </div>
            </div>
            <sections>
                <Switch>
                    <Route path="/cafeteria">
                        <Cafeteria
                            theme={theme}
                            setTheme={setTheme}
                        />
                    </Route>
                    <Route path="/tabacaria">
                        <Tabacaria
                            theme={theme}
                            setTheme={setTheme}
                        />
                    </Route>
                    <Route path="/presentes">
                        <Presentes
                            theme={theme}
                            setTheme={setTheme}
                        />
                    </Route>
                    <Route path="/curiosidades">
                        <Curiosidades
                            theme={theme}
                            setTheme={setTheme}
                        />
                    </Route>
                    <Route path="/contato">
                        <Contato
                            dispositive={dispositive}
                        />
                    </Route>
                    <Route path="/">
                        <Home
                            theme={theme}
                            setTheme={setTheme}
                        />
                    </Route>
                </Switch>
            </sections>
            <div ref={refFooter} className={`footer ${dispositive} animate__animated`}>
                <div className={"bar"}>
                    <div className={"left"}/>
                    <div className={`logo lhide`}>
                        {
                            window['isMobile']
                                ? <img src={`/images/cachimbo-${theme}.svg`} width={112}/>
                                : <a href={"/contato"}>
                                    <i className={"icon-sort-asc"}/><br/>FALE CONOSCO
                                </a>
                        }
                    </div>
                    <div className={"right"}/>
                </div>
                {
                    window['isMobile'] && <div className={"main-menu"}>
                        <a href="/">HOME</a>
                        <a href="/cafeteria">CAFETERIA</a>
                        <a href="/tabacaria">TABACARIA</a>
                        <a href="/presentes">PRESENTES</a>
                        <a href="/curiosidades">CURIOSIDADES</a>
                        <a href="/contato">FALE CONOSCO</a>
                    </div>
                }
            </div>
        </AppWrapper>
        // </Router>
    );
}

export default App;
