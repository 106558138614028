import React, {useEffect, createRef, useState} from "react";
import TinySlider from "tiny-slider-react";
import {Wrapper} from "./style";

const categories = [
    {
        image: 'canetas',
        label: 'Canetas',
        products: [
            {
                image: "1.png",
                label: "Caneta Caran D'ache de Prata",
            }, {
                image: "2.png",
                label: "Kit Caneta e Lapiseira Parker Laka Com Rodia",
            }, {
                image: "3.png",
                label: "Harley Davidson - De Pena",
            }, {
                image: "4.png",
                label: "Rondon Since 1896",
            }, {
                image: "5.png",
                label: "Caneta Sensa - Ante Gravidade",
            }
        ]
    },
    {
        image: 'canivetes',
        label: 'Canivetes',
        products: [
            {
                image: "1.png",
                label: "Canivete Alicate Multiuso Dobrável"
            }, {
                image: "2.png",
                label: "Canivete Eberle tools mini sem ponta"
            }, {
                image: "3.png",
                label: "Canivete Suíço Victorinox - abridor de garrafa"
            }, {
                image: "4.png",
                label: "Canivete LAN"
            }, {
                image: "5.png",
                label: "Faca Artesanal de Aço - Gaúcha"
            }
        ]
    },
    {
        image: 'jogos',
        label: 'Jogos',
        products: [
            {
                image: "1.png",
                label: "Baralho Copag 100% plástico número grande",
            }, {
                image: "2.png",
                label: "Baralho Fournier Espanhol",
            }, {
                image: "3.png",
                label: "Baralho kem Americano Poker",
            }, {
                image: "4.png",
                label: "Copo Artesanal para Poker - médio",
            }, {
                image: "5.png",
                label: "Roleta",
            }
        ]
    },
    {
        image: 'outros',
        label: 'Outros',
        products: [
            {
                image: "1.png",
                label: "Garrafa Térmica de Aço 1L."
            }, {
                image: "2.png",
                label: "Carteira Mágica TC - couro"
            }, {
                image: "3.png",
                label: "Caixa Coleção Relógios (6 uni.)"
            }, {
                image: "4.png",
                label: "Porta Whisky Cantil color 7oz"
            }, {
                image: "5.png",
                label: "Porta Whisky Cantil galão de gasolina dourado"
            }
        ]
    }
];

const imagePath = "/images/presentes";
const imagesRef = createRef();
const imgStyles = {
    width: "auto",
    height: "220px",
    objectFit: "cover"
};
const loadingImage =
    "data:image/gif;base64,\
    R0lGODlhAQABAPAAAMzMzAAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==";

const settings = {
    lazyload: true,
    nav: false,
    mouseDrag: true,
    loop: true,
    items: 2,
    gutter: 5,
    controls: false,
};
export const Presentes = () => {
    const [active, setActive] = useState(categories[0]);

    useEffect(() => {
        window.scrollTo(0, 0)
        // console.log(imagesRef.current.build());
        // // console.log(imagesRef.current.slider.destroy());
        // // console.log(imagesRef.current.slider.rebuild());
    }, [active]);

    return (
        <Wrapper isMobile={window['isMobile']}>
            <div className={`slider ${window["dispositive"]}`}>
                <div>
                {
                    categories.map((category, index) => {

                        const status = active.image === category.image ? "on" : "off";

                        return (
                            <div key={`category-${index}`} onClick={() => setActive(category)} className={status}>
                                <div>
                                    <img src={`${imagePath}/icons/${window["dispositive"]}/${category.image}-${status}.svg`}/>
                                </div>
                                {!window['isMobile'] && category.label}
                            </div>
                        )
                    })
                }
                </div>
            </div>
            {
                window['isMobile'] && <div className={"label"}>{active.label}</div>
            }
            <div id={"images"} className={`images ${window["dispositive"]}`}>
                {
                    active.products.map((product, index) => (
                        <div>
                            <img
                                // src={""}
                                src={`${imagePath}/products/${active.image}/${product.image}`}
                                alt=""
                                // style={imgStyles}
                            />
                            <div>{product.label}</div>
                        </div>
                    ))
                }
            </div>

                {/*<TinySlider settings={settings} ref={imagesRef}>*/}
                {/*    {active.products.map((el, index) => (*/}
                {/*        <div key={index} style={{ position: "relative" }}>*/}
                {/*            <img*/}
                {/*                className={`tns-lazy-img`}*/}
                {/*                src={loadingImage}*/}
                {/*                data-src={`${imagePath}/products/${active.image}/${el.image}`}*/}
                {/*                alt=""*/}
                {/*                style={imgStyles}*/}
                {/*            />*/}
                {/*            <div>{el.label}</div>*/}
                {/*        </div>*/}
                {/*    ))}*/}
                {/*</TinySlider>*/}

        </Wrapper>
    );
}