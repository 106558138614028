import styled from "styled-components";

export const Wrapper = styled.div`
    height: 100%;
    min-height: 1000px;
    padding: ${(props) => props.isMobile ? 'inherit' : '148px 0 76px'};
    
    h2 {
        font-size: 30px;
        > span {
            font-family: Raleway; 
            font-size: 15px;
        }
    }
    
    p {
        font-size: 15px;
    }
    
    p.strong {
        font-weight: bold;
    }
    
    .title {
        display: flex;
        
        img {
            margin-right: 1.5rem;
        }
    }
    
    .title + div {
        font-size: 15px;
        text-align: right;
        margin-bottom: 2rem;
        margin-top: -10px;
    }
    
    .title-old {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: bold;
        
        img {
            margin-left: .5rem;
        }
    }
    
    .title-old + ul {
        list-style-type: none;
        padding-left: 0px;
        
        li {
            border-bottom: 1px solid rgba(255, 255, 255, .6);
            line-height: 26px;
            padding-left: 1rem;
            margin-bottom: 1rem;
        }
        
    }
    
    .banner {
        background-image: url("/images/curiosidades/mobile/banner.jpg");
        background-size: cover;
        background-position: center ${(props) => props.isMobile ? 'bottom' : '80%'};
        height: ${(props) => props.isMobile ? '270px' : '460px'};
    }
    
    .content {
        padding: 4rem 1.5rem;
        > div {
            max-width: 720px;
            margin: 0 auto;
        }
    }
    
    .items > div:nth-child(even){
        background-color: #000;
    }
    
    .items > div:nth-child(odd){
        background-color: #935E40;
    }
    
    > div:last-child {
        padding-bottom: 110px;
    }
    
    .content.old{
        background-color: black !important;
    }
`