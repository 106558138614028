import React from "react";
import styled from "styled-components";

const instagramImages = [0, 1, 3, 4, 5, 6, 7, 0];

const DesktopWrapper = styled.div`
    background-color: #000;
    padding-top: 32px;
    padding-bottom: 100px;
    
    > div:not(.full) {
        max-width: 1366px;
        width: 1140px;
        margin: 0 auto;
        text-align: center;
                
        h3 {
            font-size: 32px;
            padding-bottom: 20px;
        }
    }
    
    div:nth-child(2) {
        display: flex;
        img {
            width: fit-content;
            padding: 24px 0;
        }
    }
`

const Desktop = () => {
    return (
        <DesktopWrapper>
            <div>
                <h3>Conheça também nosso Instagram</h3>
                <p>
                    <a href={"https://www.instagram.com/bocadepitobh/"} target={"_blank"}>
                        <span className="icon-instagram"/> bocadepitobh
                    </a>
                </p>
            </div>
            <div className={"full"}>
                {
                    instagramImages.map((i) => (
                        <img
                            src={`/images/home/${i}.png`}
                        />
                    ))
                }
            </div>
            <div>
                <p>
                    Shopping 5ª Avenida, Loja 29 - Piso B, R. Alagoas, 1314 - Funcionários, Belo Horizonte - MG,
                    30130-160<br/>
                    <strong><a href={"tel:3132810106"}>Telefone: (31) 3281-0106</a></strong>
                </p>
            </div>
        </DesktopWrapper>
    );
}

const MobileWrapper = styled.div`
    background-color: #000;
    color: #FFF;
    padding-top: 64px;
    padding-bottom: 100px;
    text-align:center;
    
    h3 {
        font-size: 24px;
        padding-bottom: 8px;
    }
    
    h5 {
        font-size: 18px;
        font-weight: bold;
    }
    
    > div {
        display: flex;
        
        img {
            width: 33.33%;
            height: auto;
        }
    }
    
`
const Mobile = () => {
    return (
        <MobileWrapper>
            <h3>Conheça também nosso<br/> Instagram</h3>
            <p>
                <a href={"https://www.instagram.com/bocadepitobh/"} target={"_blank"}>
                    <span className="icon-instagram"/> bocadepitobh
                </a>
            </p>
            <div>
                {
                    instagramImages.slice(0, 4).map((i) => (
                        <img
                            src={`/images/home/${i}.png`}
                        />
                    ))
                }
            </div>
            <p>
                Shopping 5ª Avenida, Loja 29 - Piso B<br/>
                R. Alagoas, 1314 - Funcionários<br/>
                Belo Horizonte - MG. 30130-160<br/><br />
                <strong><a href={"tel:3132810106"}>Telefone: (31) 3281-0106</a></strong>
            </p>
            <span className={"icon-copyright"}/> Copyright
        </MobileWrapper>
    );
}

export const Slide5 = ({dispositive}) => {
    return (
        <div className={dispositive + " full"}>
            {dispositive === 'mobile' ? <Mobile dispositive={dispositive}/> : <Desktop dispositive={dispositive}/>}
        </div>
    );
}