import React from "react";
import styled from "styled-components";

const MobileWrapper = styled.div`
    background-color: #000;
    color: #FFF;
    text-align: center;
    padding: 64px 0;
    
    h3 {
        font-size: 24px;
        padding-bottom: 24px;
    }
`
const Mobile = () => {
    return (
        <MobileWrapper>
            <h3>O Point</h3>
            <p>
                <strong>daqueles que tem bom gosto</strong> <br/>
                onde se reúnem pessoas de vários <br/>
                segmentos da cidade.
            </p>
            <p>
                Aqui acontecem os mais raros<br/>
                e diferentes "causos" da cidade.
            </p>
            <p>
                Ambiente alegre e descontraído, onde <br/>
                os clientes mais assíduos "batem <br/>
                ponto" para saborear os deliciosos <br/>
                cafés com biscoitos, acompanhados de <br/>
                um bom e agradável papo.
            </p>
        </MobileWrapper>
    );
}

export const Slide1M = ({dispositive}) => {
    return (
        <div className={dispositive+" full"}>
            <Mobile dispositive={dispositive}/>
        </div>
    );
}