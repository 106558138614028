import styled, {createGlobalStyle} from "styled-components";

export const Global = createGlobalStyle`
    body {
        background-image: url("/images/home/${props => props.dispositive}/bg.jpg");
        background-position: top center;
    }
    
    div.header {
        position: ${props => props.dispositive === 'mobile' ? 'relative' : 'fixed'} !important;
        background-image: ${props => props.dispositive === 'mobile' ? 'inherit' : "url(\"/images/home/desktop/menu.jpg\")"};
        background-size: cover;
        background-position: top center;
    }
`

export const HomeWrapper = styled.div`
    > div {
        > div {
            padding: ${props => props.isMobile ? "110px 0 110px" : "132px 0 76px"};
            overflow: hidden;
            
            &:not(.full){
                max-width: 1140px;
                margin: 0 auto;
            }
            
            &.full{
                padding: 0;
            }
    
            /*
            h2, h3, h4, p {
                text-align: center;
            }
            */
   
            h4 {
                font-size: 16px;
                position: ${props => props.isMobile ? "absolute" : "inherit"};
                width: 100%;
                left: 0;
                margin-top: ${props => props.isMobile ? "-20px" : "inherit"};
                font-weight: 500;
            }

            h4 + h3 {
                margin-top: 1rem;
            }

            p {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
            }
        }
    }
`