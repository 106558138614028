import React, {useEffect, useRef, useState} from "react";
import InputMask from 'react-input-mask';
import {Wrapper} from "./style";

const Text = () => (
    <>
        <h3>OLÁ!</h3>
        <p>Eu sou o Geraldinho.</p>
        <p>Aqui, primamos por um atendimento personalizado e uma boa prosa regada ao café especial da Boca de Pito,
            torrado e coado na hora.</p>
        <p className={"strong"}>Agende seu horário para atendimento exclusivo!</p>
    </>
);

const Form = () => {
    const form = useRef(null);
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(false);
    const [name, setName] = useState("");
    const [area, setArea] = useState("");
    const [phone, setPhone] = useState("");
    const url = process.env.NODE_ENV === "development" ? "//127.0.0.1:9090" : "//api.bocadepito.com.br"

    useEffect(() => {
        setValid(name.length > 0 && area.length === 2 && phone.length === 10);
    }, [name, area, phone]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData(form.current)

        setLoading(true);

        try {
            const res = await fetch(url + "/emails/send", {method: 'POST', body: data});
            const json = await res.json();
            if(json.success){
                alert("Mensagem enviada com sucesso!");
                window.top.location.href = "/";
            }

        }catch (e) {
            alert("Ocorreu um erro ao enviar sua mensagem");
        }finally {
            setLoading(false);
        }
    }

    return (
        <form ref={form} onSubmit={handleSubmit}>
            <input
                name={"test"}
                type={"hidden"}
                value={process.env.NODE_ENV === "development" ? 1 : 0}
            />
            <div>
                <label>Nome:</label>
                <input
                    name={"name"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    readOnly={loading}
                />
            </div>
            <div>
                <label>Whatsapp:</label>
                <div className={"inline"}>
                    <div style={{width: 110}}>
                        <input
                            name={"country"}
                            readOnly={true}
                            value={"+55"}
                        />
                        <label>Brazil</label>
                    </div>
                    <div style={{width: 110}}>
                        <InputMask
                            name={"area"}
                            mask={"99"}
                            maskChar={null}
                            value={area}
                            onChange={(e) => setArea(e.target.value)}
                            readOnly={loading}
                        />
                        <label>DDD</label>
                    </div>
                    <div>
                        <InputMask
                            name={"phone"}
                            mask={"99999-9999"}
                            maskChar={null}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            readOnly={loading}
                        />
                    </div>
                </div>
            </div>
            <div className={"submit"}>
                <div className={"welcome"}>SERÁ UM PRAZER TE ATENDER!</div>
                <button
                    type={"submit"}
                    disabled={!valid || loading}
                    children={loading ? "Enviando..." : "Enviar"}
                    style={{
                        cursor: "pointer"
                    }}
                />
            </div>
        </form>
    )
};

const Mobile = () => (
    <>
        <div className={"banner"}/>
        <div className={"content"}>
            <Text/>
            <Form/>
        </div>
    </>
);

const Desktop = () => (
    <>
        <div className={"w50"}>
            <div>
                <Text/>
                <Form/>
            </div>
            <div/>
        </div>
        <div className={"w100"}>
            <div>
                Política de Privacidade<br/>
                <span className={"icon-copyright"}></span> Copyright
            </div>
            <div>
                Shopping 5ª Avenida, Loja 29 - Piso B, R. Alagoas, 1314<br/>Funcionários, Belo Horizonte - MG, 30130-160
                <div>
                    <div><a href={"tel:3132810106"}>Telefone: (31) 3281-0106</a></div>
                    <div>
                        <a href={"https://www.instagram.com/bocadepitobh/"} target={"_blank"}>
                            <i className={"icon-instagram"}/>bocadepitobh
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export const Contato = ({dispositive}) => {

    return (
        <Wrapper>
            <div className={dispositive}>
                {dispositive === "mobile" ? <Mobile/> : <Desktop/>}
            </div>
        </Wrapper>
    );
}